import React, { Component, Suspense, useContext, useState, useEffect } from 'react'
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Routers,
  Router,
  HashRouter,
} from 'react-router-dom'
import { isAuthenticated } from 'src/services/storage'

import Status from './Status'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Login = React.lazy(() => import('../../views/pages/login/Login'))

// Containers
const DefaultLayout = React.lazy(() => import('../../layout/DefaultLayout'))

const AppRoutes = () => {

  const Private = ({ children }) => {

    if (!isAuthenticated()) {

      return <Navigate to="/login" />
    }

    return children
  }

  return (
    <HashRouter>
      <Status />
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/Login" name="Login Page" element={<Login />} />
          <Route
            path="*"
            name="Home"
            element={
              <Private>
                <DefaultLayout />
              </Private>
            }
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default AppRoutes
