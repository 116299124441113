import React, { Component, Suspense } from 'react'
import './scss/style.scss'
import AppRoutes from './components/auth/AppRoutes'
import { CToaster } from '@coreui/react'
import { Toast } from './components/toast/index'
import { Modal } from './components/Modal/Modal'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    return (
      <>
        <Modal/>
        <CToaster ref={this.myRef} push={<Toast/>} placement="top-end" />
        <AppRoutes />
      </>
      
    )
  }
}

export default App
