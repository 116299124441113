/* eslint-disable react/prop-types */
import axios from 'axios'
import React, { createContext, useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { getSessionData, login,logout } from 'src/services/storage'

const AccountContext = createContext()

export function AccountContextProvider({children}){

    const [user,setUser] = useState()
    const [isLogged,setIsLogged] = useState(false)

    const dispatch = useDispatch()

    const authenticateUser = async (email,senha)=>{
        try{
            const {data} = await axios.post(`${process.env.REACT_APP_SECRET_SERVER}/login`,{email,senha}) //api.post('/login',{email,senha});
    
            setUser(data)

            login({...data,email})

            setIsLogged(true);

       }catch(e){
            console.log(e)
            dispatch({ type: 'set', toast: true })
            dispatch({ type: 'set', messageToast: 'Erro ao realizar login!!' })
            dispatch({ type: 'set', colorToast: 'warning' })
       }
    }
 
    const authSession = () =>{
        const data = getSessionData()

        if(!data)
            return;

        setUser(data);

        setIsLogged(true)
        
    }

    useEffect(()=>{
        authSession()
    },[])

    return (
        <AccountContext.Provider 
            value={{ 
                isLogged,
                user,
                authenticateUser,
                authSession
            }}
        >
            {children}
        </AccountContext.Provider>
    )
}


export const useAccount = () =>{
    const context =  useContext(AccountContext);

    return context;
}