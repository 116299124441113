import axios from "axios";

export const TOKEN = "@authSharror";
export const Uid ="id";
export const Access = "key"
export const isAuthenticated = () => localStorage.getItem(TOKEN) !== null;
export const getSessionData = (key = TOKEN) => JSON.parse(localStorage.getItem(key));

export const getToken = () => {
  const session = getSessionData();

  if(!session)
    return false

  return session.token
}
export const login = data => {
   localStorage.setItem(TOKEN, JSON.stringify({...data}));
};
export const logout = () => {
  localStorage.removeItem(TOKEN);
};