import React, { useContext, useEffect } from 'react'
import { useAccount } from 'src/context/AccountContext'

const Status = () => {
  const { user } = useAccount()

  
}

export default Status
