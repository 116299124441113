import React, { useRef, useState } from 'react'

import {
  CToast,
  CToastBody,
  CToastClose,
} from '@coreui/react'

import { useDispatch, useSelector } from 'react-redux'

export const Toast = () => {

    const showToast = useSelector(st=>st.toast)
    const messageToast = useSelector(st=>st.messageToast)
    const color = useSelector(st=>st.colorToast)

    const dispatch = useDispatch()

    const close = ()=>{
      dispatch({ type: 'set', toast: false })
      dispatch({ type: 'set', messageToast: '' })
      dispatch({type:'set', colorToast:null})
    } 

    return (
        <CToast 
          autohide={true} 
          visible={!!showToast} 
          //color="warning"    
          color={color ? color : 'success'}      
          delay={5000}
          onClose={e=>close()}
        >
          <div className="d-flex">
            <CToastBody color='white'>{messageToast}</CToastBody>
            <CToastClose className="me-2 m-auto" white />
          </div>
        </CToast>

    )
  }