import React, { useState } from 'react'

import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react'

import { useDispatch, useSelector } from 'react-redux'

export const Modal = () => {
    const showModal = useSelector(st=>st.modal)
    const modalMessage = useSelector(st=>st.modalMessage)
    const modalFunction = useSelector(st=>st.modalCallback)

    const dispatch = useDispatch()

    const close = ()=>{
      dispatch({ type: 'set', modal: false })
      dispatch({ type: 'set', modalMessage: '' })
      dispatch({type:'set', modalCallback:null})
    } 

    const calling=()=>{

        if(!!modalFunction)
            modalFunction()
        
        close()
    }

    return (
      <>
        <CModal visible={showModal} onClose={() => close()}>
          <CModalHeader>
           
          </CModalHeader>
          <CModalBody>{modalMessage}</CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => close()}>
              fechar
            </CButton>
            <CButton color="primary" onClick={e=>calling()}>Ok</CButton>
          </CModalFooter>
        </CModal>
      </>
    )
  }
  